import { createRouter, createWebHistory } from 'vue-router'
import Sign_In from '../views/Sign_In.vue'
// import Password from '../views/Password.vue'

const routes = [
  {
    path: '/',
    name: 'Sign_In',
    component: Sign_In
  },
  {
    path: '/firma/kayit',
    name: 'Sign_Up',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Sign_Up.vue')
  },
   {
    path: '/firma/sifre-yenile',
    name: 'Password',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
     component: () => import(/* webpackChunkName: "about" */ '../views/Password.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
