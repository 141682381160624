<template>
  <div id="nav">
    <!-- <router-link to="/">Anasayfa</router-link> |
    <router-link to="/about">Hakkında</router-link> |
    <router-link to="/contact">İletişim</router-link> -->
  <router-view/>
  </div>
</template>

<style>

</style>
