<template>
  <div class="login-box">
    <div class="login-logo">
      <a href="index2.html">
        <img src="/assets/dist/img/nakpro-logo1.png" style="max-width: 200px;">
        <h3 style="color: #FFFFFF">Giriş Yap</h3>
      </a>
    </div>
    <div class="login-box-body">
      <p class="login-box-msg">
        <b>Telefon numarası</b> ve
        <b>şifre</b> ile giriş yapabilirsiniz</p>


        <div class="row">
          <div class="col-md-12">
            <div class="form-group has-feedback">
              <input v-model="gsm" required="" type="text" class="form-control" placeholder="Telefon Numarası" style="background-color: #13263c; border: solid 1px #1a304a; color: #FFFFFF; border-radius: 5px;">
              <span class="glyphicon glyphicon-user form-control-feedback"></span>
            </div>
          </div>

          <div class="col-md-12">
            <div class="form-group has-feedback">
              <input v-model="password" required="" type="password" class="form-control" placeholder="Şifre" style="background-color: #13263c; border: solid 1px #1a304a; color: #FFFFFF; border-radius: 5px;">
              <span class="glyphicon glyphicon-lock form-control-feedback"></span>
            </div>
          </div>

          <div class="col-md-12">
            <div class="form-group has-feedback">
              <button type="submit" @click="get" class=" btn-block" style="padding: 7px 0px; border-radius: 3px; background-color: #13263c; border: solid 1px #13263c;">GİRİŞ YAP</button>
            </div>
          </div>

          <div class="col-md-12 text-center">
            <router-link to="/firma/kayit">Yeni hesap oluşturmak için buraya tıklayın</router-link>
            <br>
            <router-link to="/firma/sifre-yenile">Şifrenizi unuttuysanız buraya tıklayın.</router-link>
          </div>

          <div class="col-md-12"></div>
        </div>
    
    </div>

    <div class="row">
      <div class="col-md-12 text-center">
        <div style="margin-top: 15px; font-size: 13px; color: #EEEEEE; font-size: 13px;">
          Nakpro v.1.0
          <br> Her Hakkı Mahfuzdur | &copy;
          2022 
          <br>
          <a target="_blank" style="color: #FFFFFF; font-weight: 500; font-size: 13px;" href="https://nakpro.web.tr">www.nakpro.web.tr</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from "axios";


export default {
  data (){
    return {
      gsm: null,
      password: null,
    }
  },
  methods: {
    get() {
      let data = {
        gsm: this.gsm,
        password: this.password,
      };
      // axios.post('http://127.0.0.1:81/api.nakpro.web.tr/', JSON.stringify(data))
      axios.post('https:///api.nakpro.web.tr/', JSON.stringify(data))
      .then( (response) => {
        console.log(response.data);
      })
      .catch( (response) => {
        console.log(response);
      });
    }
  },

};

</script>

<style>
ul li {list-style: none; border: solid 1px #CCC; padding: 5px; margin: 10px;}
</style>

